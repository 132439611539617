@import 'BaseForms';

.input {
  background: white;
  &--with-placeholder {
    .input__field {
      height: 50px;
      border: 1px solid #acacac;
      &:hover,
      &:focus {
        border-color: $brand-primary;
        & + .input__label {
          color: $brand-primary;
        }
      }
      &:focus {
        & + .input__label {
          top: -19px;
        }
      }
    }
  }

  .input__label {
    color: $text-color;
    font-weight: normal;
    top: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &--has-icon {
    background-repeat: no-repeat;
    .input__field {
      padding-left: 60px;
    }
    .input__label {
      left: 50px;
    }
  }
  &--icon-mail {
    position: relative;
    &:after {
      content: '';
      height: 26px;
      width: 25px;
      z-index: 100;
      position: absolute;
      top: 16px;
      left:20px;
      background-repeat: no-repeat;
      background-image: url('#{$package-path}/Images/Layout/Mail.svg');
      background-size: auto 18px;
    }
  }
  &--icon-lock {
    position: relative;
    &:after {
      content: '';
      height: 26px;
      width: 25px;
      z-index: 100;
      position: absolute;
      top: 12px;
      left: 16px;
      background-repeat: no-repeat;
      background-image: url('#{$package-path}/Images/Layout/Lock.svg');
      background-size: auto 25px;
    }
  }
  &--icon-search {
    width: 100%;
    position: relative;
    &:after {
      content: '';
      height: 26px;
      width: 26px;
      z-index: 100;
      position: absolute;
      top: 12px;
      left:16px;
      background-repeat: no-repeat;
      background-image: url('#{$package-path}/Images/Layout/Search.svg');
      background-size: 26px 26px;
    }
  }
}

.button {
  @include font-headline(18px);
  border-radius: 0;
  background: none;
  border: 0;
  &--primary {
    color: white;
    background-color: $brand-secondary;
    padding: 3px $unit;
    &:hover {
      background-color: $brand-primary;
    }
  }
}

.form-controls {
  margin-top: 8px;
}

// Safetyform {
.safety-form {
  margin: 0 auto 0 auto;
  max-width: 800px;

  .safety-form__message {
    height: 120px;
    resize: none;
    overflow: auto;
  }
  &__subject-container {
    margin-top: -7px;
  }
}

.btn.btn-primary,
.button--safetyform {
  border-radius: 0;
  background-color: white;
  border: 1px solid $brand-grey;
  padding: 15px;
  padding-right: 70px;
  margin-right: 20px;
  background-image: url('#{$package-path}/Images/Layout/Arrow.svg');
  background-repeat: no-repeat;
  background-position: center right 20px;
  background-size: 20px 21px;
  color: $brand-primary;
  width: 360px;
  &:focus {
    background-image: url('#{$package-path}/Images/Layout/Arrow.svg');
    background-repeat: no-repeat;
    background-position: center right 15px;
    background-color: white;
  }
  &:hover {
    background-color: white;
    color: $brand-primary;
    border-color: $brand-primary;
  }
}

.enrollment-form {
  &__headline {
    margin-bottom: 0px;
  }
  &__fieldset {
    margin-bottom: 30px;
  }
  max-width: $content-width;
  margin: 0 auto;
  textarea {
    height: 150px !important
  }
}
