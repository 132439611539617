.neos-backend {
  .sidebar {
    display: none;
  }
  .site {
    padding-left: 0;
  }
  .anchor {
    width: 100%;
    display: block;
    &:hover {
      text-decoration: none;
      color: $text-color;
    }
    margin-top: 15px;
    margin-bottom: 15px;
  }
}