//Fonts

@mixin font-content($font-size: 15px, $weight: normal) {
  font-family: 'Arial';
  font-weight: $weight;
  font-style: normal;
  font-size: $font-size;
  color: $text-color;
}

@mixin font-headline($font-size: 24px, $weight: normal) {
  @if $weight == 'bold' {
    font-family: 'HelveticaLT-Bold';
  } @else {
    font-family: 'HelveticaLTPro-Light';
  }
  font-weight: normal;
  font-style: normal;
  font-size: $font-size;
}

@mixin mobile-menu-link() {
  padding: 10px 0 10px 40px;
  cursor: pointer;
  display: block;
  background: $brand-secondary;
  color: white;
  @include font-headline(16px);
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: #fff;
  }
}