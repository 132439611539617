.login-page {
  .site__header,
  .header-image {
    position: relative;
    height: 747px;
  }

  .header-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__logo-container  {
    background-color: rgba(255,255,255,0.8);
    margin-bottom: 10px;
    padding: 8px 10px 8px 10px;
    .logo__resource {
        display: block;
        margin: 0 auto;
      }
  }

  &__logo-container,
  .login-form {
    width: 500px;
  }

  //Login Form
  .login-form {
    background-color: rgba(255,255,255,0.8);
    padding: 40px 40px 40px 40px;

    .button-container {
      text-align: right;
    }
    &__fogot-password-link {
      color: $text-color;
      font-size: 14px;
    }
    &__headline {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.password-form {
  .input--with-placeholder {
    margin-top: 0;
  }
  &__button {
    height: 50px;
  }
}
.password-expired-box {
  border: 1px solid red;
  padding: 0 25px 25px 25px;
  &__form {
    margin-top: $unit;
  }
}