$input-border: 1px solid #293f4a;
$label-color: #9f9f9f;
.input{
  &--hidden{
    display: none;
  }

  &--with-placeholder{
    position: relative;
    margin-top: 20px;

    .input__label{
      text-align:left;
      top: 0;
      right: 100%;
      width: 100%;
      margin-right: -100%;
      transition: // not padding
              background 0.2s,
              color 0.2s,
              top 0.2s,
              bottom 0.2s,
              right 0.2s,
              left 0.2s;
      position: absolute;
      color: $label-color;
      padding: 10px;
      left: 0;
    }

    .input__field {
      width: 100%;
      border: $input-border;
      background: none;
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
      padding: 10px;
      outline: 0;
      color: #000;
      box-sizing: border-box;
      height: 37px;

      &--not-empty{
        background-color: #FFF;
      }
      &--valid {
        /*border: 1px solid #090;*/
        border-left: 3px solid #090;
        padding-left: 8px;
        background-color: #ffffff;
      }

      &:focus,
      &:hover{
        border-color: #e51339;
      }

      &:focus + label {
        font-size: 80%;
        z-index: 2;
        /*text-transform: uppercase;*/
        margin-right: 0;
        width: 100%;
        padding: 2px 10px 0px 10px;
        background: none;
        color: #e51339;
        top: -16px;
      }
    }
  }

  &--multiline{
    textarea{
      resize: vertical;
    }
  }

  &--select{

    label{
      display: inline-block;
      margin-bottom: 5px;
    }

    select{
      height: 37px;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      border: $input-border;

      option[disabled]{
        color: $label-color;
      }
    }
  }
}

.form{
  .btn{
    margin-top: 20px;
  }

  .input--multicheckbox,
  .form-navigation{
    ul{
      list-style: none;
      padding: 0;
    }
  }
}
