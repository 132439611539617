$brand-primary: #003d8f;
$brand-secondary: #13579d;
$brand-grey: #dddedf;
$text-color: #2e2e2e;
$nav-highlight: #587daf;

$sidebar-width: 300px;
$container-width: 1070px;
$content-width: 800px;
$container-margin-left: 130px;
$gutter-width: 40px;
$unit: 30px;

$transition-curve: cubic-bezier(0.165, 0.84, 0.44, 1);
$transition-timing: 1s;

$package-path: '/_Resources/Static/Packages/GesagtGetan.SafetylowsAt';