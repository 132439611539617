body {
  overflow-x: hidden;
}

.mobilenavigation {
  &__level {
    width: 100%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    display: none;

    &--0 {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      display: block;
    }

    &--active {
      display: block !important;
    }

  }

  &--css3-enabled {
    .mobilenavigation__level {
      -webkit-transition: -webkit-transform ease-in-out 0.5s;
      -moz-transition: transform ease-in-out 0.5s;
      -ms-transition: transform ease-in-out 0.5s;
      -o-transition: transform ease-in-out 0.5s;
      transition: transform ease-in-out 0.5s;
      &--left {
        &.mobilenavigation__level {
          -webkit-transform: translateX(0);
          -moz-transform: translateX(0);
          -ms-transform: translateX(0);
          -o-transform: translateX(0);
          transform: translateX(0);
          &--0 {
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
            transform: translateX(-100%);
          }
        }
      }
    }
  }
}