.flowpack-searchplugin-search {
  li {
    @include font-headline(16px);
  }
  p {
    @include font-content(14px);
  }
  .input--with-placeholder {
    margin-top: 0;
  }
  .button {
    height: 50px;
  }
}

.search-result {
  margin: 50px 0 10px 0;
  &__link {
    @include font-headline(16px);
  }
}