/**
 * @license
 * MyFonts Webfont Build ID 3234414, 2016-06-17T08:40:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: HelveticaLT-Bold by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/helvetica/bold/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted (c) 1985, 1987, 1989, 1990, 1997, 1998, 1999, 2003 Linotype Library GmbH, www.linotype.com. All rights reserved. This software is the property 
 * Licensed pageviews: 500,000
 * 
 * Webfont: HelveticaLTPro-Light by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/helvetica/pro-light/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2008 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced, 
 * Licensed pageviews: 250,000
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3234414
 * 
 * © 2016 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/315a6e");


@font-face {font-family: 'HelveticaLT-Bold';src: url('#{$package-path}/Fonts/Helvetica/315A6E_0_0.eot');src: url('#{$package-path}/Fonts/Helvetica/315A6E_0_0.eot?#iefix') format('embedded-opentype'),url('#{$package-path}/Fonts/Helvetica/315A6E_0_0.woff2') format('woff2'),url('#{$package-path}/Fonts/Helvetica/315A6E_0_0.woff') format('woff'),url('#{$package-path}/Fonts/Helvetica/315A6E_0_0.ttf') format('truetype');}
@font-face {font-family: 'HelveticaLTPro-Light';src: url('#{$package-path}/Fonts/Helvetica/315A6E_1_0.eot');src: url('#{$package-path}/Fonts/Helvetica/315A6E_1_0.eot?#iefix') format('embedded-opentype'),url('#{$package-path}/Fonts/Helvetica/315A6E_1_0.woff2') format('woff2'),url('#{$package-path}/Fonts/Helvetica/315A6E_1_0.woff') format('woff'),url('#{$package-path}/Fonts/Helvetica/315A6E_1_0.ttf') format('truetype');}
 