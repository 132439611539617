body {
  background: white;
  color: $text-color;
}

.site {
  @include font-content();
}

.node--narrow {
  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;
}

.node {
  margin-bottom: 70px;
  &--headline-box {
    text-align: center;
    border-bottom: 5px solid $brand-grey;
    padding-bottom: $unit;
    margin-bottom: 50px;
  }
  &--headline {
    text-align: center;
    margin-bottom: 20px;
    &--left {
      text-align: left;
    }
    &--right {
      text-align: right;
    }
  }
  &__main-headline {
    margin-bottom: 0;
  }
  &__sub-headline {
    margin-bottom: 0;
    margin-top: -5px;
  }
}

// Text
p {
  margin: 5px 0 15px 0;
}

h1 {
  @include font-headline(40px, bold);
  color: $brand-primary;
  margin: 20px 0 20px 0;
}

h2,h3 {
  color: $brand-secondary;
  @include font-headline(24px, normal);
  margin: 15px 0 10px 0;
}

h3 {
  font-size: 22px;
}

h4,h5 {
  color: $text-color;
  margin-bottom: 0;
}

.text {
  table {
    //scroll horizontally if we run out of space
    max-width: 100%;
    overflow-x: auto;
    display: block;
  }
  ul {
    list-style-type: square;
    line-height: 2;
  }
  th {
    font-weight: bold;
    background: $brand-grey;
    border-top: 1px solid #c7c8ca;

  }
  tr {
    border-left: 1px solid #c7c8ca;
    background: #eeeeef;
  }
  tr:nth-child(even) {
    background: #fff;
  }
  th,td {
    padding: 10px;
    border-right: 1px solid #c7c8ca;
    border-bottom: 1px solid #c7c8ca;
  }
}

.text-with-image {
  &__headline {
    margin-top: -3px;
  }
}


// Gallery
.gallery {
  &__image {
    margin-bottom: 40px;
    display: block;
  }
}


.youtube {
  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;
  &__video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: $unit;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

//DocumentAccordion
.document-accordion {
  max-width: $content-width;
  margin: 0 auto 0 auto;
  &__section {
    margin-bottom: $unit;
    transition: height 0.5s;
    min-height: 31px;
  }
  .js-document-accordion__section--folded {
    .document-accordion__entries {
      overflow: hidden;
    }
    .document-accordion__fold-icon {
      transform: rotate(90deg);
    }

  }
  &__entry {
    display: block;
    padding: 10px;
    padding-right: 0;
    border-bottom: 1px solid $brand-grey;
    color: $text-color;
    &focus,
    &:hover {
      text-decoration: none;
      color: $text-color;
      background: $brand-grey;
    }
  }
  &__section-header {
    cursor: pointer;
    background: $brand-grey;
    padding: 5px 10px;
    margin-bottom: 10px;
    &:hover {
      background: lighten($brand-grey,5%);
    }
  }
  &__section-title {
    color: $text-color;
    font-size: 22px;
    margin: 0;
    float: left;

  }
  &__fold-icon {
    position: relative;
    top: 5px;
    display: block;
    float: right;
    transform: rotate(-90deg);
    transition: all .5s $transition-curve;
  }
  &__favourite-container {
    float: right;
    &--add {
      .js-favourite-form--delete {
        display:none;
      }
    }
    &--delete {
      .js-favourite-form--add {
        display:none;
      }
    }
  }
  &__file-description-container {
    float: left;
  }
  &__action-button {
    background-color: transparent;
    border: 0;
    outline: 0;
    text-indent: -500px;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  .favourite-button {
    background-image: url('#{$package-path}/Images/Layout/StarGrey.svg');
    background-size: 20px 18px;
    background-position: center center;
    background-repeat: no-repeat;
    height: 25px;
    width: 40px;
    &--on {
      background-image: url('#{$package-path}/Images/Layout/StarBlack.svg');
    }
  }
  &__file-title {
    text-overflow: ellipsis;
  }
}

