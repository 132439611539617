/* first narrow content shift takes place */
@media screen and (max-width: 1560px) {

}


/* bellow LG */
@media screen and (max-width: 1200px) {
  .button--safetyform {
    width: 100%;
  }
  .next.submit {
    float: none;
  }
  .topbar__inner {
    margin-left: 0;
  }
  .breadcrumbs,
  .site__main {
    margin-left: 20px;
  }
}


/* bellow MD */
@media screen and (max-width: 1023px) {

  /* MOBILE NAVIGATION BEGIN*/
  .menu__toggle {
    background: transparent;
    border: none;
    height: 50px;
    line-height: 50px;
    float: right;
    @include font-headline(18px);
    padding-left: 35px;
    position: relative;
    &:focus {
      outline: none;
    }
  }
  .burger {
    width: $unit;
    height: 13px;
    position: absolute;
    left: 0;
    top: 13px;

    &__stripe {
      height: 4px;
      width: 100%;
      background: white;
      margin-bottom: 6px;
      &--bottom {
        margin-bottom: 0;
      }
    }
  }

  .sidebar {
    position: static;
    height: 50px;
    background: $brand-secondary;
    border-bottom: 1px solid $brand-grey;

    .mobilenavigation__level--0 {
      top: 50px;
      margin: 0;
    }

    &__menu {
      display: none;
    }

  }
  .menu {
    &__navigation--open {
      display: block;
    }
    &__level {
      padding-left: 0;
      list-style-type: none;
      z-index: 1000;
    }
    &__list-item {
    }

    &__link {
      @include mobile-menu-link();

      &--has-children {
        background-image: url('#{$package-path}/Images/Layout/SidebarNavigationArrowFolded.svg');
        background-repeat: no-repeat;
        background-position: 20px 16px;
      }
      &--level-4,
      &--level-3,
      &--level-2 {
        font-size: 15px;
        background-size: 10px 8px;
        background-position: 20px 16px;
      }

    }
    &__toggle {
      float: right;
    }
  }
  .logo--sidebar {

    img {
      height: 35px;
      width: auto;
      display: inline-block;
      margin-left: 20px;
      margin-top: 5px;
    }
  }

  .mobilenavigation__origin {
    .menu__link {
      background-position: 20px 17px;
      background-size: 9px auto;
      background-image: url('#{$package-path}/Images/Layout/SidebarNavigationArrowUnfolded.svg');
    }
  }
  .mobilenavigation__back {
    a {
      @include mobile-menu-link();
      font-size: 14px;
      background-image: url('#{$package-path}/Images/Layout/SidebarNavigationArrowBack.svg');
      background-repeat: no-repeat;
      background-size: 5px 7px;
      background-position: 20px 17px;
    }

  }

  .site {
    padding-left: 0;
  }

  .site__main {
    margin-left: auto;
    margin-right: auto;
  }

  .footer__inner {
    margin-left: auto;
    margin-right: auto;
    .float--right {
      float: none;
    }
  }
  /* MOBILE NAVIGATION END */

  .topbar {
    &__inner {
      margin-left: 0;
    }
    &__logout-button,
    &__link {
      text-indent: -300px;
      overflow: hidden;
      width: 50px;
      height: 50px;
      padding: 0;
      background-position: center center;
    }
  }
  .dashboard__section {
    margin: 25px 0 25px 0;
    padding: 25px 0 25px 0;
  }
  .breadcrumbs {
    margin-left: $gutter-width/2;
  }

}


/* bellow SM */
@media screen and (max-width: 767px) {

  .node {
    margin-bottom: 35px;
  }

  .dashboard__section--search-and-report {
    margin-top: 0;
    padding-top: 0;
  }

  .footer {
    &__disclaimer {
      margin-bottom: 20px;
    }
    &__logos-container {
      padding-right: $gutter-width/2;
    }
  }

  h1 {
    font-size: 24px;
  }

  .document-accordion__section-title,
  h2,h3 {
    font-size: 16px;
  }

  h4,h5 {
    color: $text-color;
    margin-bottom: 0;
  }

  .site {
    font-size: 14px;
  }

  .dashboard {
    .search-field {
      margin-top: $unit;
    }
    &__safety-report-link {
      margin-top: $unit;
    }
    &__headline {
      background-size: 20px auto;
      &--favourites {
        margin-top: 40px;
      }
    }
  }

  .password-form {
    &__button {
      width: 100%;
      margin-top: 15px;
    }
  }

  .text-with-image__headline {
    margin-top: 20px;
  }

}


/* bellow XS */
@media screen and (max-width: 480px) {
  .button {
    margin-top: $unit;
  }

  .button--safetyform {
    max-width: 260px;
  }

  .login-page {
    .login-form,
    &__logo-container {
      width: 100%;
      max-width: 480px;
    }
    .login-form {
      padding: 10px;
    }
    .header-image {
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 100px;
    }
  }
  .topbar__left {
    max-width: 200px;
  }
}

@media screen and (max-width: 359px) {
  .topbar__link {
    width: 30px;
  }
  .topbar__left {
    max-width: 140px;
    overflow: hidden;
  }
}

input {
  border-radius: 0 !important;
}