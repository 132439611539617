@media screen and (min-width: 1024px) {
  .sidebar {
    position: fixed;
    top: 0;
    height: 100%;
    background: $brand-secondary;
    width: $sidebar-width;
    margin-left: -$sidebar-width;
    overflow-y: auto;
    //transition: all 0.5s ease;
    &__content {
      width: 100%;
      top: 0;
      position: absolute;
    }

    // sidebar navigation
    &__menu {
      margin-top: $unit;
      .menu {
        &__level {
          padding-left: 0;
          list-style-type: none;
        }
        &__list-item {
          margin-bottom: 15px;
          &--level-2 {
            margin-bottom: 5px;
          }
          &--level-4,
          &--level-3 {
            margin-bottom: 0;
          }
        }
        &__link {
          cursor: pointer;
          padding: 10px 0 10px 40px;
          display: block;
          @include font-headline(18px);
          color: white;
          & + .menu__level--2 {
            transition: max-height .5s ease-out;
            overflow: hidden;
          }
          &.active,
          &.current,
          &:hover {
            text-decoration: none;
            background-color: $nav-highlight;
          }
          &--has-children {
            background-image: url('#{$package-path}/Images/Layout/SidebarNavigationArrowFolded.svg');
            background-repeat: no-repeat;
            background-position: 20px 16px;
          }
          &--level-3,
          &--level-2 {
            font-size: 15px;
            background-size: 5px auto;
            background-position: 20px 18px;
            &.js-menu__link--unfolded {
              background-size: auto 5px;
              background-position: 20px 20px;
            }
          }
          &--level-4,
          &--level-3 {
            font-size: 13px;
            padding-left: 50px;
          }
        }
      }
    }

    // folded
    .js-menu__link--folded {
      & + .menu__level {
        overflow: hidden;
        max-height: 0;
        transition: max-height .5s ease-out;
      }
    }

    // unfolded or active
    .js-menu__link--unfolded {
      background-image: url('#{$package-path}/Images/Layout/SidebarNavigationArrowUnfolded.svg');
      background-repeat: no-repeat;
      background-position: 20px 20px;
      background-size: auto 8px;
    }
    //.menu__link.active,
    .js-menu__link--unfolded {
      & + .menu__level {
        max-height: 2400px;
        transition: max-height 1s ease-in;
      }
    }
  }

  .logo {
    &--sidebar {
      margin-top: 20px;
      display:block;
      img {
        margin: 35px 76px 0 60px;
        display: block;
      }
    }
  }

  .sidebar__buttons {
    width: 240px;
    margin: 50px auto 0 auto;
    border-top: 1px solid #668bbc;
    padding-top: 25px;
  }
  .font-resizer {
    float: left;
  }
 .font-resizer__link {
   cursor: pointer;
   display: inline-block;
   color: white;
   margin-right: 3px;
   line-height: 18px;
   height: 18px;
   width: 18px;
   text-align: center;
   &:hover {
     color: white;
     text-decoration: none;
   }
   &--reset {
     height: 18px;
     width: 33px;
     background-image: url('#{$package-path}/Images/Layout/FontsizeIcon.svg')
   }
 }
 .print-button {
   float: right;
   display: block;
   color: white;
   &:hover {
     color: white;
     text-decoration: none;
     cursor: pointer;
   }
 }
}

