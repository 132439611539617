body,
html,
.site,
.container-fluid {
  //height: 100%;
}

html {
  overflow-x: hidden;
}

a:hover {
  text-decoration: underline;
}

.float {
  &--left {
    float: left;
  }
  &--right {
    float: right;
  }
}

.container-fluid {
  margin-left: 0;
}
.site {
  padding-left: $sidebar-width;
  &__header {
    overflow: hidden;
    //uncomment this if we need the header to go behind the sidebar
    //margin-left: -$sidebar-width;
  }
  &__main {
    max-width: $container-width;
    margin-left: $container-margin-left;
    //flex: 1 0 auto;
  }
  &__footer {
    border-top: 1px solid $brand-primary;
    padding: 40px 0 40px 0;
    margin-top: 100px;
    margin-left: -$gutter-width/2;
    padding-left: $gutter-width/2;
    width: calc(100% + #{$gutter-width});
  }
  &__content {
    margin-top: 50px;
  }
}

.footer {
  &__inner {
    max-width: $container-width;
    margin-left: $container-margin-left;
  }
  &__disclaimer {
    color: $brand-primary;
    font-size: 14px;
  }
  &__logos {
    height: 50px;
  }
}

.no-navigation {
  .footer__inner {
    margin: 0 auto 0 auto;
  }
  &.site {
    padding-left: 0;
  }
  .site__main {
    margin-left: auto;
    margin-right: auto;
  }
}


.logo {
  display: block;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.no-padding {
  &--left {
    padding-left: 0;
  }
  &--right {
    padding-right: 0;
  }
}

.flash-messages {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1000;
  .flashmessages-ok,
  .flashmessages-error {
    display: block;
    margin-bottom: 5px;
    color: white;
    position: relative;
    background-color: #00a338;
    padding: 20px;
    animation: slideIn 1s;
    @keyframes slideIn {
      from {top: -100px;}
      to {top: 0px;}
    }
  }
  .flashmessages-error {
    background-color: #ff0000;
  }
  ul,
  li {
    margin: 0;
    padding:0;
    list-style-type: none;
  }
}

.topbar {
  border-bottom: 1px solid $brand-grey;
  &__inner {
    max-width: $container-width;
    margin: 0 auto 0 $container-margin-left + 20;
    height: 50px;
  }
  &__link {
    display: block;
    float: left;
    line-height: 49px;
    color: $text-color;
    border: 1px solid $brand-grey;
    border-left: none;
    border-bottom: none;
    padding: 0 20px 0 40px;
    background-repeat: no-repeat;
    background-position: center left 10px;
    &:first-child {
      border-left: 1px solid $brand-grey;
    }
    &:hover {
      background-color: lighten($nav-highlight, 40);
    }
    &--dashboard {
      background-image: url('#{$package-path}/Images/Layout/StarBlack.svg');
      background-size: 15px 15px;
      &:hover {
        background-image: url('#{$package-path}/Images/Layout/StarBlue.svg');
      }
    }
    &--signout {
      background-image: url('#{$package-path}/Images/Layout/UnlockGrey.svg');
      background-size: 16px 15px;
      &:hover {
        background-image: url('#{$package-path}/Images/Layout/UnlockBlue.svg');
      }
    }
    &--profile {
      background-image: url('#{$package-path}/Images/Layout/PersonGrey.svg');
      background-size: 12px 15px;
      &:hover {
        background-image: url('#{$package-path}/Images/Layout/PersonBlue.svg');
      }
    }
    input[type="submit"] {
      background: none;
      border: none;
      outline: none;
      padding:0;
      display: inline;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__link-container {
   float: right;
  }
  .search-field {
    .input__field {
      border-right: 0;
      border-top: 0;
      border-bottom: 0;
    }
  }
  &__left {
    float: left;
    width: 50%;
  }
}

.search-field {
  .input--with-placeholder {
    margin-top: 0;
  }
}

.more-link {
  background-repeat: no-repeat;
  background-image: url('#{$package-path}/Images/Layout/Arrow.svg');
  background-position: center right;
  padding-right: 25px;
  @include font-headline(16px);
}

.breadcrumbs {
  padding: 0;
  max-width: $container-width;
  margin: 15px auto 0 $container-margin-left + $gutter-width/20;

  li {
    display: inline-block;
    list-style-type: none;
    font-size: 12px;
    a {
      color: $text-color;
    }
    &:after {
      content: '>';
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}

.scroll-to-top {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: $brand-secondary;
  width: 50px;
  height: 50px;
  line-height: 61px;
  color: white;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
}