.document-accordion {
  &__delete-container {
    float: right;
  }
  &__delete-button {
    background-image: url('#{$package-path}/Images/Layout/DeleteIcon.png');
    background-size: 20px 20px;
    background-position: center center;
    height: 25px;
    width: 40px;
  }
}

.dashboard {
  margin-top: 15px;

  &-content {
    margin-top: 0;
  }
  &__section {
    margin: 30px 0 30px 0;
    padding: 70px 0 30px 0;
    &--search-and-report {
      margin-top: 20px;
      padding-top: 0;
    }
    &--news-and-changelog {
      border-top: 5px solid $brand-grey;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  &__user-files {
    border-top: 5px solid $brand-grey;
    border-bottom: 5px solid $brand-grey;
  }
  &__headline {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 22px;
    background-position: right 6px center;
    background-repeat: no-repeat;
    background-size: 26px 26px;
    &--last-seen {
      background-image: url('#{$package-path}/Images/Layout/DownloadGrey.svg');
    }
    &--favourites {
      background-image: url('#{$package-path}/Images/Layout/StarBlack.svg');

    }
  }
  &__user-element {
    margin-bottom: 20px;
    background-position: left center;
    background-repeat: no-repeat;
    float: left;
    padding-left: 20px;
    margin-right: 20px;
    font-size: 13px;
    &--user-name {
      background-size: auto 15px;
      background-image: url('#{$package-path}/Images/Layout/PersonGrey.svg');
    }
    &--last-login {
      background-size: auto 15px;
      background-image: url('#{$package-path}/Images/Layout/LockGrey.svg');
    }
    &--user-roles {
      background-size: 25px 21px;
      background-image: url('#{$package-path}/Images/Layout/PersonGrey.svg');
    }
    &--change-password {
      float: left;
      position: relative;
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 25px;
      background-size: auto 15px;
      background-image: url('#{$package-path}/Images/Layout/GearwheelGrey.svg');
      color: $text-color;
      padding-right: 35px;
      line-height: 17px;
      &:hover {
        color: $text-color;
      }
      &:after {
        content: '';
        position: absolute;
        height: 20px;
        width: 40px;
        right: 0;
        top: 2px;
        background-image: url('#{$package-path}/Images/Layout/ArrowRight.svg');
        background-size: auto 15px;
        background-repeat: no-repeat;
        background-position: 20px 0;
      }
  }


  }
  &__safety-report-link {
    @include font-headline(18px);
    border: 1px solid $brand-grey;
    color: red;
    display: block;
    padding: 0 20px 0 20px;
    line-height: 60px;
    background-image: url('#{$package-path}/Images/Layout/ArrowRed.png');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: right 10px center;
    &:focus,
    &:active,
    &:hover {
      color: red;
      text-decoration: none;
      border-color: red;
    }
  }
  &__logout {
    margin-top:20px;
    &-button {
      width: 100%;
      display: block;
    }
  }
  .input--icon-search {
    .input__field {
      height: 60px;
    }
    .input__label {
      top: 8px;

    }
    &:after {
      top: 16px;
    }
  }
}

.news-teaser {
  margin: 30px 0 30px 0;
  &__headline {
    color: $text-color;
    @include font-content(18px);
    font-weight: bold;
  }
}

.changelog {
  &__entry {
    margin-top: $unit;
  }

  &__headline {
    font-weight: bold;
  }
  &__text {
    margin: 5px 0 5px 0;
  }
  &__changedate {
    font-size: 13px;
    margin-top: 3px;
  }
  &__link {
    font-size: 13px;
  }
}

.automatic-changelog {
  margin-top: $unit;
}
